@import "sass-lib";
@media (min-width: 0px) and (max-width: 767px) {
	.wrapper{ margin: 0 20px; }
	.btn-view{ margin-top: 10px; }
	.btn{ font-size: 13px; line-height: 40px; padding: 0 20px; font-weight: 500; letter-spacing: 2px; }
	.home{
		h3{ font-size: 20px; line-height: 25px; }
		h5{ font-size: 14px; line-height: 18px; }
		.small-tittle{ margin-bottom: 15px; }
		.slider-home{
			.desc{ width: auto; margin: 0 20px; }
			h4{ width: auto; font-size: 14px; }
			h2{ font-size: 23px; margin-bottom: 15px; }
		}
		&.service{
			padding:30px 0;
			.right{
				margin-top: 10px;
			}
			.box-desc{ margin-bottom: 30px; }
			.box-service{
				margin: 0;
				.list-service{
					width: 100%; display: block; margin-bottom: 20px;
					min-height: auto; padding: 0;
					.ico{ 
						img{ width: 80px; }
					}
					.titt{
						.t-s{ color: #1b3c60; font-weight:500; }
					}
					a{ border-top: 3px solid #1b3c60; padding-top:20px;   }
				}
			}
		}
		&.product{
			padding: 30px 0;
			.top-titt{
				margin-bottom: 30px;
			}
			.box{
				.list-product{
					.capt{
						small{ font-size: 14px; font-weight: 300; line-height: 18px; height: 34px; }
					}
				}
			}
		}
		&.qoutes{
			.profile{
				float: none; display: block; margin: auto; width: 100%;
			}
			.txt{
				float: none; width: 100%; margin-top: 0; padding-top: 30px;
				i{ font-size: 13px; line-height: 18px; padding: 0 20px; @include boxSizing(border-box); display: block; }
				&:before{ left:0; top: 0; font-size:60px; opacity: 0.5; font-size:81px; }
			}
			.arr-right{ right: 10%; top: 35%; bottom: auto; }
			 .arr-left{
	            background: url(../images/material/arr-right-white.png)no-repeat center; width:12px; height: 24px; position: absolute; left:10%; top:35%; bottom: 0;
	            z-index: 2;cursor: pointer; @include transform(rotate(180deg)); 
	        }
	        .nav_info{ display: none; }
		}
		&.news{
			padding: 30px 0 80px;
			.btn-detail{ left: 0; right: 0; }
			h6{ font-size: 13px; }
			.box-news{
				position: relative; padding-bottom: 45px;
				.desc-new{ float: none; width: 100%; display: block; margin-bottom: 30px; }
				.wrap-news{ 
					float: none; width: 100%;
					.list-news{ padding: 0 10px 0 0; }
				}
				.btn-view{ position: absolute; bottom: 0; left: 0; }

			}
		}
		&.contact{ display: none; }
		&.ourclient{display: none;}
	}

	.middle{
		.container{ padding-top: 40px; }
		h3{ font-size: 20px; line-height: 24px; }
		p{
		 font-size: 14px; line-height: 18px; 
			&.f-light{ font-size: 13px; }
		}
		.banner-std{
			height: 150px;
			img{ height: 100%; width: 100%; object-fit: cover; }
			.qts{
				small{ font-size: 12px; letter-spacing: 2px; margin-bottom: 10px; }
				span{ font-size: 28px; }
			}
		}
	}
	.desc-sys{
		h4{ font-size: 16px; line-height: 20px; height: 38px; margin-bottom: 5px; }
		.box-desc{ 
			padding: 0; 
			.list{
				display: block; width: 100%; float: none; margin-bottom: 20px;
			}
		}
	}
	.box-partner{
		margin: 0 -5px;
		.list-partner{ 
			width: 33.333%; padding: 0 5px; @include boxSizing(border-box); margin-bottom: 5px;

		}
	}
	.customer{
		margin: 0 -5px;
		.box-cs{
			.list-cs{ width: 33.333%; padding: 0 5px; @include boxSizing(border-box); margin-bottom: 5px; }
		}
	}
	.tittle-middle{
		&:before{ width:50px; }
		.date-auth{
 			span{ margin-right: 10px; }
			.date {
 				padding-right: 10px;
			}
		}
	}
	.box-leftright{
		.box{
			figure{ width: 100%; float: none; display: block; margin-bottom: 20px; }
			.txt{ 
				float: none; width: 100%; display: block; 
				&:nth-child(even){ width: 100%; float: none; }
			}

		}
	}
	.sign{
		margin-top: 30px;
		figure{ 
			img{ width: 150px; }
		}
		span{ font-size: 16px; }
	}
	.wrap-content{ padding-top: 0; }
	.news-event{
		small{ margin-bottom: 15px; }
	}
	.content-tab{
		
		.tab{
			margin-bottom: 25px;
			ul{
				li{
					margin-right: 25px;
					a{ font-size: 14px; }
				}
			}
		}
	}
	.news-event{
		h2{ font-size: 17px; width: auto; line-height: 23px; height: 44px; }
		.abslt{
			left: 20px; width: auto; top: 20%; bottom: 20%;
			p{ display: none; }
		}
		.img-hig{ 
			margin-bottom: 0; height: 250px; 
			img{ height: 100%; object-fit: cover; width: 100%; }
		}
	}
	.slider-newsevent{ margin-bottom: 20px; }
	.desc-date{ 
		.tgl{ font-size: 11px; }
		.lok{ font-size: 11px; }
	 }

	.list-box{
		&.newsevent{ 
			float: none; display: block; width: 100%; margin-right: 0; margin-bottom: 20px; 
			p{ font-size: 14px; line-height: 18px; }
			h6{ font-size: 16px; }
		}
	}
	.btn-detail{ margin-top: 10px; }
	.wrap-content{
		&.no-margin{ padding-top: 0; }
	}
	.box-tittle-form{
		h2{ font-size: 22px; }
		.left{ float: none; display: block; width: 100%; margin-bottom: 20px; }
		.right{
		 	width: 100%; display: block; float: none; 
			small{ text-align: left; margin-bottom: 10px; }
		}
	}
	.section-form{
		margin-bottom: 20px;
	}
	.tittle-middle{
		.date-auth{
			.date{ font-size: 11px; }
			span{ font-size: 11px; }
		}
		&.brd-bot{ padding-bottom: 20px; }
	}
	.form{
		.row{
			.col{
				&.half{ width: 100%; float: none; display: block; margin-bottom: 10px;}
			}
		}
	}
	.border-back{
		margin: 20px; padding-bottom: 20px;
		a{ font-size: 13px; }
	}
	.search-result{
		.search-text{ margin-bottom: 25px; }
	}
	footer{
		padding-top: 30px; position: relative; overflow: hidden;
		.top{
			padding-bottom: 20px;  position: relative;
			&:before{
			 content:''; position: absolute; bottom: -1px; left: -100%; width: 100%; height: 1px; background: #ccc;
			}
			&:after{
				position: absolute; bottom: 0; right: -100%; width: 100%; height: 1px; background: #ccc; bottom: -1px;
			}
			.left{ display: none; float: none; }
			.right{ 
				float: none; text-align: center; 
				.logo{  width: 150px; margin:  0 auto; }
				.addrs{ 
					text-align: center; display: block; font-size: 13px; line-height: 18px;
					a{ display: block; }
				}
			}
		}
		.bot{
			padding: 20px 0;
			span{ font-size: 12px; line-height: 16px; }
		}
	}
	.form{
		.input-file{
			height: 40px;
			input[type='file']{ height: 40px; }
			span{ font-size: 14px;  line-height: 40px; height: 40px;}
		}
	}
	
	.middle .middle-ul ul li{ font-size: 13px; }
	.desc-date{ margin-bottom: 15px; }
	.box-tittle-form .right small{ display: none; }
	.form{
		.submit-mid{ 
			text-align: left; margin-top: 30px;
			.btn{ padding: 0 30px;  }
		}
	}
	.wrap-job{
		margin-top: 30px;
		.list-job{ position: relative; }
		.date-post{ font-weight: 500; }
		.right{
			span{ width: 30px; height: 30px; position: absolute; top: 0; right: 0; bottom: 0; margin: auto 0; }
		}
		.job{ padding-right: 35px; }
	}
	.tittle-middle{ margin-bottom: 20px; }
	.content-tab .tab ul li a{ @include transition(none); }
	.under-con{
        min-height: 750px;
        .logo{ 
        	left: 2%; top: 5%; 
			img{ width: 150px; }
        }
        .wrapper{ 
            padding-top: 160px;  width: auto; margin: 0 20px;
            .text-content{ 
                width: auto; 
                h1{ line-height: 40px; font-size:32px; }
                h3{ line-height: 16px; margin-bottom: 10px; }
                p{ font-size: 14px; line-height: 24px; }
            }
            .form{
                input, textarea{ width: 100%; border:1px solid #dddada; padding: 15px 20px; }
            }
        }
        .left{ float: none; }
        .right{ float: none; }
    }
}


@media (min-width: 0px) and (max-width: 419px) {
	.btn-loadmore{ 
		padding: 0 20px; line-height: 30px; 
		span{ font-size: 14px; }
	}
	header{
		.menumobile{
			nav{
				ul{
					> li{
						font-size: 22px; margin-bottom: 20px;
						> a{  }
					}
				}
			}
		}
	}
	.home{
		.slider-home{
			.nav_info{
				span{
					&.pos{ font-size: 14px; }
					&.total{ font-size: 14px; }
				}
			}
		}
	}
	.pg-down{
		width: 40px; height: 40px;
	}
	.paging{
		a{ padding: 0 5px; line-height: 20px;}
		.arr{
			padding: 0 10px;
			&.prev{ margin-right: 20px; }
			&.next{ margin-left: 20px; }
		}

	}
	.search-result{
		input[type='text']{ font-size: 26px; height: 40px; }
		small{ font-size: 13px; }
	}
	.news-event{
		h2{ margin-bottom: 10px; }
		.btn{ line-height: 30px; font-size: 10px; padding: 0 15px;}
	}
	.desc-date{ margin-bottom: 10px; }
	.middle{
		.banner-std{
				.qts{
					small{ font-size: 12px; letter-spacing: 2px; margin-bottom: 10px; }
					span{ font-size: 20px;line-height: 25px; }
				}
			}
	}
}